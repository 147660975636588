<template>
  <div>
    <!-- Non-custodial -->
    <div class="div-4 is-hidden-mobile" />
    <section class="section">
      <div class="container">
        <div class="columns is-vcentered flip-wrapper">
          <div class="column is-5 is-offset-1">
            <h2 class="title is-spaced title-font">
              Your keys, your coins
            </h2>
            <p class="subtitle subtitle-spaced">
              Morpher Wallet is non-custodial and self-hosted. It's engineered
              such that we can't access your keys or your funds. You are always
              in full control: take your wallet with you anywhere (like move to
              Metamask).
            </p>
          </div>
          <div class="column is-5 is-offset-1">
            <figure class="image">
              <picture>
                <source
                  type="image/webp"
                  srcset="@/assets/img/wallet/keys.webp"
                >
                <source
                  type="image/png"
                  srcset="@/assets/img/wallet/keys.png"
                >
                <img
                  src="@/assets/img/wallet/keys.png"
                  alt="Crypto seed phrase and private key"
                >
              </picture>
            </figure>
          </div>
        </div>
      </div>
    </section>
    <!-- Recoverable -->
    <section class="section">
      <div class="container">
        <div class="columns is-vcentered">
          <div class="column is-5 is-offset-1">
            <figure class="image">
              <picture>
                <source
                  type="image/webp"
                  srcset="@/assets/img/wallet/recovery.webp"
                >
                <source
                  type="image/png"
                  srcset="@/assets/img/wallet/recovery.png"
                >
                <img
                  src="@/assets/img/wallet/recovery.png"
                  alt="Trusted wallet recovery"
                >
              </picture>
            </figure>
          </div>
          <div class="column is-5 is-offset-1">
            <h2 class="title is-spaced title-font">
              Never lose your funds
            </h2>
            <p class="subtitle subtitle-spaced">
              The days of lost crypto funds are over. Add a trusted online
              account as the recovery method for your wallet. Use services you
              love like Google, Facebook, and VK to reset a forgotten password.
            </p>
            <p class="is-size-14">
              <b-icon icon="fas fa-lock" pack="fas" class="is-size-7" />
              Your keys remain encrypted and are never shared.
            </p>
          </div>
        </div>
      </div>
    </section>
    <!-- 2FA -->
    <section class="section">
      <div class="container">
        <div class="columns is-vcentered flip-wrapper">
          <div class="column is-5 is-offset-1">
            <h2 class="title is-spaced title-font">
              Protect your crypto with 2-Step
            </h2>
            <p class="subtitle subtitle-spaced">
              Crypto security made easy. Protect yourself from account takeovers
              and compromised passwords. 2-Step is an extra lock on your wallet,
              letting you authenticate yourself via email or smartphone.
            </p>
          </div>
          <div class="column is-5 is-offset-1">
            <figure class="image">
              <picture>
                <source
                  type="image/webp"
                  srcset="@/assets/img/wallet/2step.webp"
                >
                <source
                  type="image/png"
                  srcset="@/assets/img/wallet/2step.png"
                >
                <img
                  src="@/assets/img/wallet/2step.png"
                  alt="2-Step wallet verification"
                >
              </picture>
            </figure>
          </div>
        </div>
      </div>
    </section>
    <div class="div-4 is-hidden-mobile" />
  </div>
</template>

<script>
export default {
	name: "Features",
};
</script>

<style scoped>
.section .title {
	margin-bottom: 1rem;
}
.div-4 {
	margin-bottom: 4rem;
}
</style>
