<template>
  <div>
    <section class="section">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-5">
            <h2 class="title title-font">
              For Developers: Invisible DApp wallet
            </h2>
            <p class="subtitle subtitle-spaced">
              Treat your users with the Web 2.0 flow they know, and the Web 3.0
              features they want. The Morpher SDK offers deep integration with
              custom events and callbacks for seamless UX.
            </p>
            <!-- Features -->
            <div class="features">
              <div class="check-row">
                <b-icon icon="fas fa-check-circle" pack="fas" class="icon" />
                <p>
                  Free and open-source
                </p>
              </div>
              <div class="check-row">
                <b-icon icon="fas fa-check-circle" pack="fas" class="icon" />
                <p>
                  Web-based universal compatibility
                </p>
              </div>
              <div class="check-row">
                <b-icon icon="fas fa-check-circle" pack="fas" class="icon" />
                <p>
                  Zero-configuration keystore
                </p>
              </div>
              <div class="check-row">
                <b-icon icon="fas fa-check-circle" pack="fas" class="icon" />
                <p>
                  Hosted and self-hosted solutions
                </p>
              </div>
              <div class="check-row">
                <b-icon icon="fas fa-check-circle" pack="fas" class="icon" />
                <p>
                  Deep code-level customization options
                </p>
              </div>
            </div>
            <!-- Add to Your DApp -->
            <div>
              <a
                href="https://docs.google.com/forms/d/e/1FAIpQLSdh00G9PenekJDMQoc4Nu8bYOpDFPULs0f04yuRpriTqnhrmg/viewform?usp=sf_link"
                title="Add to Your Dapp"
                rel="nofollow"
              >
                <button class="button cta-button" tag="button">
                  Add to Your DApp
                </button>
              </a>
            </div>
          </div>

          <div class="column is-1" />

          <div class="column is-5">
            <p class="mini-title">
              <b-icon icon="fas fa-book" pack="fas" size="is-small" />
              Documentation
            </p>
            <a
              href="https://github.com/Morpher-io/MorpherWallet/blob/master/docs/audit-doc.pdf"
              title="Technical Breakdown"
              class="transition-faster"
            >
              <div class="link-card">
                <div class="link-text">
                  <p class="has-text-weight-semibold">
                    Technical Breakdown
                  </p>
                  <p class="explain-text">
                    Detailed description of wallet architecture and user flows.
                  </p>
                </div>
                <b-icon icon="fas fa-chevron-right" pack="fas" class="icon" />
              </div>
            </a>
            <a
              href="https://github.com/Morpher-io/MorpherWallet/blob/master/docs/requestsigning.md"
              title="Request Signatures"
              class="transition-faster"
            >
              <div class="link-card">
                <div class="link-text">
                  <p class="has-text-weight-semibold">
                    Request Signatures
                  </p>
                  <p class="explain-text">
                    How backend requests are signed.
                  </p>
                </div>
                <b-icon icon="fas fa-chevron-right" pack="fas" class="icon" />
              </div>
            </a>
            <a
              href="https://github.com/Morpher-io/MorpherWallet/blob/master/docs/packages.md"
              title="Key Frameworks"
              class="transition-faster"
            >
              <div class="link-card">
                <div class="link-text">
                  <p class="has-text-weight-semibold">
                    Key Frameworks
                  </p>
                  <p class="explain-text">
                    Important frameworks the wallet relies on.
                  </p>
                </div>
                <b-icon icon="fas fa-chevron-right" pack="fas" class="icon" />
              </div>
            </a>
            <a
              href="https://github.com/Morpher-io/MorpherWallet/tree/master/docs"
              title="All Docs"
              class="transition-faster"
            >
              <div class="link-card">
                <div class="link-text">
                  <p class="has-text-weight-semibold">All Docs</p>
                  <p class="explain-text">
                    All documentation on Morpher Wallet.
                  </p>
                </div>
                <b-icon icon="fas fa-chevron-right" pack="fas" class="icon" />
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
	name: "Developers",
};
</script>

<style scoped>
.title {
	margin-bottom: 2rem;
}
.subtitle {
	font-size: 16px;
}
.check-row {
	display: flex;
	align-items: center;
	margin-bottom: 0.5rem;
}
.check-row > .icon {
	color: #00c386;
	margin-right: 0.5rem;
}
.check-row > p {
	font-size: 18px;
	font-weight: 500;
}
.mini-title {
	margin-bottom: 1rem;
	font-weight: 600;
	font-size: 1.25rem;
}
a .link-card {
	color: #444;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: #fff;
	padding: 1rem;
	margin-bottom: 1rem;
	/* box-shadow: 0px 3px 6px 0 rgb(0 0 0 / 12%); */
	box-shadow: 0px 2px 6px 0 rgb(0 0 0 / 12%);
	border-radius: 10px;
	transition-delay: 0s;
	transition-duration: 0.25s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
a:hover .link-card {
	/* box-shadow: 0px 2px 4px 0 rgb(0 0 0 / 15%); */
	box-shadow: 0px 4px 8px 0 rgb(0 0 0 / 15%);
	color: #000;
	transition-delay: 0s;
	transition-duration: 0.25s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.link-text {
	margin-right: 2rem;
}

.explain-text {
	font-size: 14px;
	line-height: 14px;
}

.button.cta-button {
	margin-top: 1rem;
	border-radius: 5px;
	background-color: #00c386;
	border-color: transparent;
	color: #fff;
	font-weight: 700;
	font-size: 1.125rem;
	height: 2.875rem;
	padding: 0 1.5rem;
	transition-delay: 0s;
	transition-duration: 0.3s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.button.cta-button:hover {
	background-color: #00d492;
	border-color: #00d492;
	box-shadow: 0px 2px 4px rgba(0, 161, 111, 0.1),
		0px 4px 10px rgba(0, 161, 111, 0.2);
	transition-delay: 0s;
	transition-duration: 0.3s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
</style>
