<template>
  <div>
    <section class="section is-med">
      <div class="container">
        <div class="columns is-centered has-text-centered has-text-left-mobile">
          <div class="column is-8">
            <h2 class="title title-font mb-30">
              Serious Wallet Security
            </h2>
            <figure class="image is-600 mb-30">
              <picture>
                <source
                  type="image/webp"
                  srcset="@/assets/img/wallet/security.webp"
                >
                <source
                  type="image/png"
                  srcset="@/assets/img/wallet/security.png"
                >
                <img
                  src="@/assets/img/wallet/security.png"
                  alt="Military grade wallet security"
                >
              </picture>
            </figure>
          </div>
        </div>

        <div class="columns is-centered">
          <div class="column is-3">
            <h3 class="title is-4">
              Military-Grade Encryption
            </h3>
            <p>
              We use best-in-class hashing and encryption methods. All done
              directly directly on your device. Your keys are never exposed, not
              even on your hard drive.
            </p>
          </div>
          <div class="column is-1 is-hidden-mobile" />
          <div class="column is-3">
            <h3 class="title is-4">
              Independent Audits
            </h3>
            <p>
              Don't just take our word for it, we ask top blockchain security
              firms to review our codebase. They make sure you get the safest
              wallet experience possible.
            </p>
            <div class="audit-link">
              <a
                href="https://github.com/Morpher-io/MorpherWallet/blob/master/docs/Audit%20Report%20-%20Morpher%20Wallet%20%5B20210224%5D.pdf"
                title="Audit Report"
                class="transition-faster"
              >Read the latest audit report ></a>
            </div>
          </div>
          <div class="column is-1 is-hidden-mobile" />
          <div class="column is-3">
            <h3 class="title is-4">
              Full Transparency
            </h3>
            <p>
              Our code is continually vetted by the open source community and
              security specialists taking part in our bug bounty program. The
              wallet code is safer and stronger thanks to many.
            </p>
            <div class="audit-link">
              <router-link
                to="/bug-bounty"
                title="Bug Bounty"
                class="transition-faster"
              >Bug bounty program ></router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
	name: "Security",
};
</script>

<style scoped>
.section {
	background-color: #f6f7f8;
}
.section.is-med {
	padding-top: 4rem;
	padding-bottom: 4rem;
}
.mb-30 {
	margin-bottom: 30px;
}
.image.is-600 {
	max-width: 600px;
	margin-left: auto;
	margin-right: auto;
}
.title.is-4 {
	margin-bottom: 0.5rem;
}
.audit-link {
	font-weight: 500;
	margin-top: 0.5rem;
}
</style>
