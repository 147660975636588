<template>
  <section class="hero">
    <Navbar />

    <div class="hero-body">
      <div class="container">
        <div class="columns is-vcentered">
          <div class="column is-7-tablet is-6-desktop is-5-widescreen">
            <h1 class="title is-size-1 title-font">
              The crypto wallet you won't lose
            </h1>
            <div class="column is-11-tablet is-paddingless">
              <p class="subtitle is-size-5">
                Your secure, open-source, and fully recoverable gateway to
                thousands of markets on Ethereum.
              </p>
            </div>
            <div class="column is-paddingless">
              <div class="buttons">
                <router-link to="/register" title="Trade" class="mr-12">
                  <button tag="button" class="button cta-blue">
                    Use on Morpher
                  </button>
                </router-link>
                <a
                  href="https://github.com/Morpher-io/MorpherWallet"
                  rel="nofollow"
                  title="Start Building"
                >
                  <button tag="button" class="button cta-outline">
                    <p>
                      <b-icon icon="fab fa-github" pack="fab" size="is-small" />
                      {{ "Start Building" }}
                    </p>
                  </button>
                </a>
              </div>
              <div class="is-size-14">
                Or try the
                <a
                  class="transition-faster"
                  target="_blank"
                  href="https://wallet.morpher.com/"
                  title="Launch Morpher Wallet"
                >wallet in your browser</a>.
              </div>
            </div>
          </div>
          <div
            class="column is-narrow is-hidden-touch is-hidden-desktop-only"
          />
          <div class="column">
            <figure class="image is-5by4">
              <picture>
                <source
                  type="image/webp"
                  srcset="@/assets/img/wallet/wallet_shot.webp"
                >
                <source
                  type="image/png"
                  srcset="@/assets/img/wallet/wallet_shot.png"
                >
                <img
                  src="@/assets/img/wallet/wallet_shot.png"
                  alt="Morpher Wallet feature shot"
                >
              </picture>
            </figure>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Navbar from "@/components/partials/Navbar";

export default {
	name: "Hero",
	components: {
		Navbar,
	},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hero {
	background-color: #d2e8f8;
}
.hero .subtitle {
	line-height: 1.5;
}
.hero .title {
	margin-bottom: 1rem;
}
.buttons {
	display: flex;
	margin-top: 1.5rem;
	margin-bottom: 0.5rem;
}
.button.cta-blue {
	border-radius: 5px;
	background-color: #228cdb;
	border-color: transparent;
	color: #fff;
	font-weight: 700;
	font-size: 1.125rem;
	height: 2.875rem;
	padding: 0 1.25rem;
	transition-delay: 0s;
	transition-duration: 0.3s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.button.cta-blue:hover {
	background-color: #169cff;
	border-color: #169cff;
	box-shadow: 0px 2px 4px rgba(34, 140, 219, 0.1),
		0px 4px 10px rgba(34, 140, 219, 0.2);
	transition-delay: 0s;
	transition-duration: 0.3s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.button.cta-outline {
	outline-offset: 2px;
	border-radius: 5px;
	border-width: 2px;
	color: #4c4c4c;
	font-weight: 700;
	font-size: 1.125rem;
	height: 2.875rem;
	padding: 0 1.25rem;
	transition-delay: 0s;
	transition-duration: 0.3s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.button.cta-outline:hover {
	background-color: #333;
	border-color: #333;
	color: #fff;
	transition-delay: 0s;
	transition-duration: 0.3s;
	transition-property: all;
	transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.mr-12 {
	margin-right: 0.75rem;
}
</style>
